import React from "react";
import ReleaseAug2024 from "./Images/ReleaseAug2024.png";
import ReleaseJul2024 from "./Images/ReleaseJul2024.png";
import ReleaseJun2024 from "./Images/ReleaseJun2024.png";
import ReleaseMay2024 from "./Images/ReleaseMay2024.png";
import { Link } from "react-router-dom";
import ReleaseSep24 from "./Images/ReleaseSep2024.png";

function ReleasesFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h1 className="title-container">Versions</h1>
          <h2 className="title-secondary">
            Découvrez les dernières versions de Pedra ici
            <br />
          </h2>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>SEPTEMBRE 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/versions/renovez-et-meublez-en-un-clic"
              >
                Pedra : Rénovez et meublez vos espaces en un seul clic. De plus,
                de meilleurs résultats en 'Auto' et une meilleure préservation
                de l'espace.
              </Link>
            </h2>
          </div>
          <div className="picture">
            <Link
              to="/fr/versions/renovez-et-meublez-en-un-clic"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseSep24}
                alt="Versions Pedra du mois de septembre 24 – Bannière décorative"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>AOÛT 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres"
              >
                Pedra : Améliorations de l'ameublement, styles personnalisés et
                préservation des fenêtres
              </Link>
            </h2>
            <div className="value-prop-text">
              Résultats ultra-réalistes, utilisation de vos propres styles, et
              amélioration de la préservation des fenêtres
            </div>
          </div>
          <div className="picture">
            <Link
              to="/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseAug2024}
                alt="Versions Pedra du mois d'août 24 – Bannière décorative"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JUILLET 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu"
              >
                Pedra : Ajoutez des objets 3D, rendez le ciel bleu en 1 clic, et
                améliorez ou éclaircissez automatiquement les images
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJul2024}
                alt="Versions Pedra du mois de juillet 24 – Bannière décorative"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JUIN 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone"
              >
                Pedra : Rénovez les intérieurs depuis votre téléphone avec l'IA,
                modifiez les légendes des images et éditez les filigranes
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJun2024}
                alt="Versions Pedra du mois de juin 24 – Bannière décorative"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>MAI 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/releases/vide-chambres-et-enlever-objets-avec-ia"
              >
                Pedra : Videz les pièces et supprimez des objets, styles de
                rénovation et nouvelle interface
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/fr/releases/vide-chambres-et-enlever-objets-avec-ia"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseMay2024}
                alt="Versions Pedra du mois de mai 24 – Bannière décorative"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleasesFr;
